
<template>
  <v-layout style="height: 30px;;">
    <v-system-bar window style="height: 30px; font-size: 1.25rem;padding: 0 2rem;background: #c6ccdd;">
      <v-spacer></v-spacer>
      <v-icon icon="fa-solid fa-location-dot" class="me-2"></v-icon>
      Calle Republica de Chile 220 Urb. La Negrita
      <v-icon icon="fa-solid fa-mobile-screen-button" class="me-2"></v-icon>
      054 619 272      
      
      <v-btn icon="fab fa-facebook" variant="text"></v-btn>
      <v-btn icon="fab fa-instagram" variant="text" class="ms-2"></v-btn>
      <v-btn icon="fab fa-twitter" variant="text" class="ms-2"></v-btn>
      <v-btn icon="fa-solid fa-envelopes-bulk" variant="text" class="ms-2"></v-btn>
      <v-btn icon="fas fa-user account" variant="text" class="ms-2"></v-btn>
      
    </v-system-bar>
  </v-layout>
</template>
<!--<template>

<v-card
elevation="0"
rounded="0"
width="100%"
class="bg-grey text-right"
>
<v-card-text>
    <v-btn
      class="mx-4"
      icon="fab fa-facebook"
      variant="plain"
      color="white"
    ></v-btn>
    <v-btn
      class="mx-4"
      icon="fab fa-instagram"
      variant="plain"
      color="white"
    ></v-btn>
    <v-btn
      class="mx-4"
      icon="fab fa-twitter"
      variant="plain"
      color="white"
    ></v-btn>
    <v-btn
      class="mx-4"
      variant="plain"
      color="white"
    ><h2><i class="fa-solid fa-envelopes-bulk"></i> contacto@incopingenieros.com</h2></v-btn>
    <v-btn
      class="mx-4"
      variant="plain"
      color="white"
    ><h2><i class="fa-solid fa-phone"></i> 054 619 272</h2></v-btn>

  </v-card-text>

</v-card>


</template>-->