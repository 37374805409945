<template>
  <div class="header">  
    <router-link @click="scrollToTop()" to="/" class="logo"><img :src="require(`../assets/images/INCOP5.png`)" alt="" />
    </router-link>
    <div class="icons">
            <div id="menu-btn" class="fas fa-bars menu-btn" @click="showNav"></div>        
        </div>
    <nav class="navbar">
        <router-link @click="scrollToTop()" to="/about">Nosotros</router-link>
        <router-link @click="scrollToTop()" to="/proyectos">Proyectos</router-link>
        <router-link @click="scrollToTop()" to="/servicios">Servicios</router-link>
        <router-link @click="scrollToTop()" to="/Contactanos">Contactanos</router-link>
        <router-link @click="scrollToTop()" to="/blog">Blog</router-link>
        <router-link @click="scrollToTop()" to="/noticias">Noticias</router-link>
    </nav>

  
    </div>

</template>
  
  <script>
  import { defineComponent } from "vue";
  

  export default defineComponent({
    components:{        
    },
    
    methods: {
      scroll(refName) {
        const element = document.getElementById(refName);
        element.scrollIntoView({ behavior: "smooth" });
      },
      scrollToTop() {
            window.scrollTo(0, 0);
      },
      showNav: function () {
            let navbar = document.querySelector('.header .navbar');
            navbar.classList.toggle('active');
      },
    },
  });
  </script>
  
  <style>

.header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #14235e;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0% 0% 0%;
}

.header .logo {
    font-size: 2.0rem;
    font-weight: bolder;
    color: #130f40;
    padding: 0rem 6%;
}

.header .logo img {
    padding-right: .0rem;
    color: #27ae60;
}

.header .navbar a {
    font-size: 1.7rem;
    margin: 0 1rem;
    color: #fff;
}

.header .navbar a:hover {
    color: #27ae60;
}

.header .navbar a.router-link-exact-active {
    color: #f38609;
}

.header .icons div {
    height: 4.5rem;
    width: 4.5rem;
    line-height: 4.5rem;
    font-size: 2rem;
    background: #f7f7f7;
    color: #130f40;
    border-radius: .5rem;
    margin-left: .3rem;
    cursor: pointer;
    text-align: center;
}

.header .icons div:hover {
    color: #fff;
    background: #27ae60 !important;
}

.header .icons a.router-link-exact-active .cart {
    background: #f38609;
    color: white;
}

#menu-btn {
    display: none;
}

.header .icons .account .drop-down-select {
    display: none;
    position: absolute;
    margin-left: -50px;
    list-style-type: none;
}

.header .icons .account .drop-down-select a {
    text-decoration: none;
    color: #130f40;
    font-size: 15px;
    font-weight: 500;
    float: left;
    width: 95px;
    border-radius: 5%;

}

.header .icons .account .drop-down-select.active {
    display: block !important;
}

.header .icons .account .drop-down-select.active a {
    background-color: #f7f7f7;
}

.header .icons .account .drop-down-select.active a:hover {
    background-color: #f38609;
    color: white;
}

/* .header .icons .account:hover .drop-down-select {
    display: block;
} */

.header .icons .account:hover .drop-down-select a {
    background-color: #f7f7f7;

}

.header .icons .account:hover .drop-down-select a:hover {
    background-color: #f38609;
    color: white;
}

@media (min-width: 769px) {
    .header .icons .account:hover .drop-down-select {
        display: block;
    }
}

@media (max-width: 768px) {
    .header .navbar {
        position: absolute;
        top: 99%;
        left: 0;
        right: 0;
        background: #fff;
        border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    .header .navbar.active {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    .header .navbar a {
        font-size: 2rem;
        margin: 2rem;
        display: block;
    }

    #menu-btn {
        display: inline-block;
    }

}

@media (max-width: 576px) {
    .header .navbar a {
        font-size: 1.5rem;
        margin: 0;
    }
}
  </style>