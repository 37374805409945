<template>
  <v-app>
    <v-main>
      <barinfo></barinfo>
      <NavBar/>
      <router-view/>
      <FooterComponent/>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterComponent from './components/FooterComponent.vue';
import barinfo from './components/BarInfo.vue';

import { mapActions } from 'vuex';
import { mapState } from 'vuex';

export default {
  name: 'App',
  components:{
    NavBar,
    FooterComponent,
    barinfo
  },
  data: () => ({
    //
  }),
  created() {
        this.getFoodsData()
    },

    computed: {
        ...mapState(["admin"])
    },

    methods: {
        ...mapActions(["getFoodsData"])
    }
    }
</script>