<template>
  <v-main>    

  <div class="ccw_plugin chatbot" style="bottom:5px; right:5px;">
    <div class="ccw_style9 animated no-animation ccw-no-hover-an">
        <a target="_blank" href="https://web.whatsapp.com/send?phone=51913305561&amp;text=" rel="noreferrer" class="img-icon-a nofocus">   
            <img class="img-icon ccw-analytics" id="style-9" data-ccw="style-9" style="height: 48px;" :src="require(`../assets/images/whatsapp-logo.png`)" alt="WhatsApp chat">
        </a>
    </div>
  </div>

    <HeroKu />
<v-container fluid>

  <quienessomos></quienessomos>
  <servicios></servicios>


  <section id="nuestrosclientes">
    <div class="row">
    <div class="about-article">
                <h3>Nuestros Clientes</h3>
            </div></div>
          <v-row>              
              <v-col cols="4" v-for="(cliente, i) in clientes" :key="i">
                <v-img :src="cliente.img"  max-width="150px" class=" ml-auto mr-auto"></v-img>
            </v-col>            
          </v-row>
  </section>

<section class="about-section">
  <div class="row">
            <div class="about-article">
                <h3>Portafolio Emblematico</h3>
            </div>
        </div>

        <div class="row gallery">
            <div class="wrapper">
                <img :src="require(`../assets/images/sliderbar/img1.jpg`)" alt="">
                <img :src="require(`../assets/images/sliderbar/img2.png`)" alt="">
                <img :src="require(`../assets/images/sliderbar/img3.png`)" alt="">
                <img :src="require(`../assets/images/sliderbar/img5.jpg`)" alt="">
                <img :src="require(`../assets/images/sliderbar/img1.jpg`)" alt="">
                <img :src="require(`../assets/images/sliderbar/img2.png`)" alt="">
                <img :src="require(`../assets/images/sliderbar/img3.png`)" alt="">
                <img :src="require(`../assets/images/sliderbar/img5.jpg`)" alt="">
                <img :src="require(`../assets/images/sliderbar/img5.jpg`)" alt="">
                <img :src="require(`../assets/images/sliderbar/img1.jpg`)" alt="">


            </div>
        </div>
</section>


 
</v-container>
  </v-main>

  
</template>

<script>
import { defineComponent } from "vue";
import HeroKu from "../components/HeroKu.vue";
import quienessomos from '../components/QuienesSomos.vue';
import servicios from '../components/Servicios.vue'


export default defineComponent({
  name: "HomeView",
  setup() {
    return {
      slider2: 50,
      items: [  
      {
          img: require("@/assets/images/proyectos.png"),
          title: "PROYECTOS",
          text: "Proyectos de gran enbargadura de alta calidad",
        },
        {
          img: require("@/assets/images/consultoria.png"),
          title: "CONSULTORIA",
          text: "Consultoria en obras de gran embargadura de todas las especialidades en el Peru",
        },
        {
          img: require("@/assets/images/construccion.png"),
          title: "CONSTRUCCION",
          text: "Ejecucion de Obras en todas las especialidades de la ing. civil de alta calidad",
        },      
      ],
      features: [
        {
          img: require("@/assets/images/mision7.png"),
          title: "VISION",
          text: "Obtener la categoria mas alta a nivel de empresa afines a nuestro mercado, con servicios que contengan excelencia total, humana y tecnologica, sin descuidar las normas de seguridad y conservacion ambiental",
        },
        {
          img: require("@/assets/images/vision7.png"),
          title: "MISIÓN",
          text: "Lograr que nuestros proyectos de construccion tengan la maxima seguridad contra todo tipo de desastres, sean funcionables y ademas sean viables economicamente.",
        },
        {
          img: require("@/assets/images/valores7.png"),
          title: "VALORES",
          text: " Liderazgo, innovación, trabajo en equipo, calidad y seguridad",
        },
      ],
      clientes: [
        {
          img: require("@/assets/images/yura.png"),
          title: "VISION",   
        },
        {
          img: require("@/assets/images/gloria.png"),
          title: "MISIÓN",
        },
        {
          img: require("@/assets/images/supermix.jpg"),
          title: "VALORES",
        },
        {
          img: require("@/assets/images/sedapar.png"),
          title: "VALORES",
        },
      ],

      
    };
    
  },
  components: {
    HeroKu,
    quienessomos,
    servicios
},
});
</script>
<style >
.v-container {
  padding: 0px 0 0px 0;
}

#about p {
    font-size: 1.4rem;
    color: #666;
    line-height: 2;
    padding: 1rem 0;
}
.first {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 4rem 2rem;
}

.child{
  padding:2%;
}

.imgHover {
  padding: 0 200px;
}

#nuestrosclientes{
  background: rgb(180, 218, 233);

}

#nosotros {
  background-image: url("../assets/images/fondo3.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: cente8;
  height: auto;
  padding:2% 8%;
  
}


.chatbot {
    position: fixed;
    z-index: 99999999999999;
}
div.ccw_plugin, .inline {
    display: inline;
}
.ccw_plugin .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}
.animated {
    animation-duration: 1.25s;
}

.about-article {
    margin-top: 0px;
    padding: 0px;
    text-align: center;
    background-color: #5b27ae;
    width: 100%;
}

.about-article h3 {
    font-family: 'cursive', system-ui;
    font-size: 32px;
    color: white;
}

.about-section .gallery {
    overflow: hidden;
    padding: 100px;
}

.about-section .gallery .wrapper {
    position: relative;
    flex-grow: 1;
    margin: auto;
    max-width: 1200px;
    max-height: 1200px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 2vmin;
    justify-items: center;
    align-items: center;
}

.about-section .gallery .wrapper img {
    z-index: 1;
    grid-column: span 2;
    max-width: 100%;
    margin-bottom: -52%;
    border: #5b27ae 50px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    transform: scale(1);
    transition: all .25s;
}

.about-section .gallery .wrapper img:nth-child(7n + 1) {
    grid-column: 2 / span 2;
}

.about-section .gallery .wrapper img:hover {
    z-index: 2;
    transform: scale(2);
}

@media (max-width: 768px) {
    .about-section .about-content img {
        width: 250px;
    }

    .about-section .gallery .wrapper img:hover {
        transform: scale(2.5);
    }
}

@media (max-width: 576px) {
    .about-section .about-content img {
        width: inherit;
    }

    .about-section .about-content {
        display: block;
    }

    .about-section .about-content .about-content-text {
        padding-left: 0px;
    }

    .about-section .gallery {
        padding: 50px 0px;
    }

    .about-section .gallery .wrapper img {
        max-width: 100%;

    }

    .about-section .gallery .wrapper {
        grid-gap: 2vmin;
    }

    .about-section .gallery .wrapper img:hover {
        transform: scale(2);
    }

}
</style>