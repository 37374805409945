<template>

    <div id="nosotros">
  <v-row align="center" justify="center">
    <v-col sm="4" class="hidden-xs-only">
      <v-img src="../assets/images/Quienes-Somos2.jpg" />
    </v-col>
    <v-col cols="8" sm="8" class="white--text text-left">
        <br/>
        <br/>
        <br/>
      <div class="text-h3 text-center">QUINES SOMOS?</div>
      <br />
      <div class="text-h5 text-center">
        Somos una empresa de ingeniería de proyectos tanto en el campo de la
        consultoría y la construcción. INCOP Ingenieros cuenta con mucha
        experiencia, demostrando a lo largo de los años nuestra calidad en la
        infinidad de trabajos desarrollados
      </div>
      <v-btn
        rounded
        outlined
        href="#"
        target="_blank"
        large
        color="white"
        class="mt-4"
      >
        <v-icon class="mr-2"> VER MAS .. </v-icon>
        VER MAS
      </v-btn>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" sm="4" v-for="(feature, i) in features" :key="i">
      <v-hover v-slot="{ isHovering, props }" open-delay="200">
        <v-card
          :elevation="isHovering ? 1 : 3"
          :class="{ 'on-hover': isHovering }"
          class="mx-auto"
          height="320"
          max-width="350"
          v-bind="props"
        >
          <v-img
            :src="feature.img"
            max-width="130px"
            min-height="130px"
            class="ml-auto mr-auto"
          ></v-img>
          <div class="text-h4 text-center">{{ feature.title }}</div>
          <div class="text-h5 text-center">{{ feature.text }}</div>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</div>

</template>
<script>
import { defineComponent } from "vue";


export default defineComponent({
  name: "HomeView",
  setup() {
    return {
        features: [
        {
          img: require("@/assets/images/mision7.png"),
          title: "VISION",
          text: "Obtener la categoria mas alta a nivel de empresa afines a nuestro mercado, con servicios que contengan excelencia total, humana y tecnologica, sin descuidar las normas de seguridad y conservacion ambiental",
        },
        {
          img: require("@/assets/images/vision7.png"),
          title: "MISIÓN",
          text: "Lograr que nuestros proyectos de construccion tengan la maxima seguridad contra todo tipo de desastres, sean funcionables y ademas sean viables economicamente.",
        },
        {
          img: require("@/assets/images/valores7.png"),
          title: "VALORES",
          text: " Liderazgo, innovación, trabajo en equipo, calidad y seguridad",
        },
      ],
    }
}
})
</script>

<style>

#nosotros {
  background-image: url("../assets/images/fondo3.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center;
  height: auto;
  padding:0% 10%;
  
}

</style>
