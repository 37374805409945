<template>
    <v-container fluid>
  <div id="nuestrosservicios">
    
      <div class="about-article">
        <h3>Nuestros Servicios</h3>
    
    </div>

    <div class="first">
      <v-row>
        <v-col cols="4" v-for="(items, i) in items" :key="i">
          <div class="child">
            <v-img
              :src="items.img"
              max-width="150px"
              class="ml-auto mr-auto"
            ></v-img>

            <div class="text-h4" style="padding-top: 10px;">{{ items.title }}</div>
            <div class="text-h5">
              {{ items.text }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
</div>
</v-container>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "HomeView",
  setup() {
    return {
      items: [
        {
          img: require("@/assets/images/proyectos.png"),
          title: "PROYECTOS",
          text: "Proyectos de gran enbargadura de alta calidad",
        },
        {
          img: require("@/assets/images/consultoria.png"),
          title: "CONSULTORIA",
          text: "Consultoria en obras de gran embargadura de todas las especialidades en el Peru",
        },
        {
          img: require("@/assets/images/construccion.png"),
          title: "CONSTRUCCION",
          text: "Ejecucion de Obras en todas las especialidades de la ing. civil de alta calidad",
        },
      ],
    };
  },
});
</script>

<style>

#nuestrosservicios {
  background-image: url("../assets/images/fondo3.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center;
  height: auto;
  padding-top:0%;
  
  
}

</style>