<template>
      
    <servicios></servicios>
  
</template>

  <script>
import { defineComponent } from 'vue';
import servicios from '../components/Servicios.vue'

export default defineComponent({
  name: "ServiciosView",
  components: {
    servicios
  }
})
</script>