<template>
<div class="HeroBlock">
    <v-carousel hide-delimiters cycle>
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      cover
    >
    <div class="d-flex fill-height justify-start align-end">
        <div class="text-h2">
          <div class="text-h3 text-center">
            {{ item.title }}            
          </div>          
          <router-link @click="scrollToTop()" to="/about" class="btn">Solicita Cotización</router-link>        
      </div>
    </div>
  </v-carousel-item>
  
  </v-carousel>

  
</div>

</template>
<script>
export default{
    name: 'HeroKu',
    data () {
      return {
        items: [
          {
            src: require(`../assets/images/sliderbar/img1.jpg`),
            title: 'Edificaciones'
          },
          {
            src: require(`../assets/images/sliderbar/img2.png`),
            title: 'Ecologia Villa Médica'
          },
          {
            src: require(`../assets/images/sliderbar/img3.png`),
            title: 'Muelle de un Proyecto Minero en el Sur del Pais'
          },
          {
            src: require(`../assets/images/sliderbar/img5.jpg`),
            title: 'Planta Industrial'
          },
        ]
      }
    }
};
</script>

<style>
.HeroBlock{
    position:relative;
    top:0%;
}


.text-h2{
  margin: 1%;
  padding: 1%;
  background: #d09104;
  border-radius: 1rem;
  text-transform: capitalize;
  transition: .2s linear;
  opacity: 0.8;
  font-weight: bolder;
  text-align: left;
  color: white;
}
</style>