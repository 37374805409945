<template>
  
<quienessomos></quienessomos>

</template>
<script>
import { defineComponent } from "vue";
import quienessomos from "@/components/QuienesSomos.vue";

export default defineComponent({
  name: "HomeView",
  components:{
    quienessomos,
  }
})

</script>
