<template>
<v-container>
<v-row id="contact">
    <v-col cols="12" sm="12" class="px-16" >
      <v-row>
        <v-col cols="12" sm="4">
          <div class="child">
            <h1>Contact info.</h1>
            <v-btn
              icon="fas fa-map-marker-alt"
              color=""
              class="mt-10"
              variant="outlined"
            ></v-btn
            ><br />
            <span class="text-caption">Calle Republica de Chile 220 Urb. La Negrita, Arequipa</span
            ><br />
            <v-btn
              icon="fas fa-phone-alt"
              color=""
              class="mt-10"
              variant="outlined"
            ></v-btn
            ><br />
            <span class="text-caption">054 619 272 </span> <br />
            <span class="text-caption">999 999 999 </span> <br />
            <v-btn
              icon="fas fa-envelope"
              color=""
              class="mt-10"
              variant="outlined"
            ></v-btn
            ><br />
            <span class="text-caption">contacto@incopingenieros.com </span> <br />
            
          </div>
        </v-col>
        <v-col cols="12" sm="8">
          <h1 class="mt-8">ENVIAR MENSAJE</h1>
          <v-divider></v-divider>
          <span class="text-caption"
            >Llene el formulario para ponernos en contacto contigo.....</span
          >
          <v-row class="mt-10">
            <v-col cols="12" sm="6">
              <v-text-field
                label="Name"
                persistent-hint
                variant="outlined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Phone No"
                persistent-hint
                variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-textarea
            label="Message"
            persistent-hint
            variant="outlined"
          ></v-textarea>
          <v-btn color="#FBDF7E" class="mt-2">ENVIAR</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>
</template>