import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView  from '../views/AboutView.vue'
import ProyectosView  from '../views/ProyectosView.vue'
import ContactanosView  from '../views/ContactanosView.vue'
import BlogView  from '../views/BlogView.vue'
import NoticiasView  from '../views/NoticiasView.vue'
import ServiciosView  from '../views/ServiciosView.vue'
import LoginView  from '../views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/proyectos',
    name: 'proyectos',
    component: ProyectosView
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogView
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: ServiciosView
  },
  {
    path: '/Noticias',
    name: 'noticias',
    component: NoticiasView
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: '/contactanos',
    name: 'contactanos',
    component: ContactanosView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
